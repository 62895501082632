import { regionsList } from './regionsList';
import { AddressLookup } from './address_lookup';
import * as Stickyfill from './stickyfill';

// Init address lookup;
AddressLookup();

$.extend({
    replaceTag: function (element, tagName) {
        var newTag = $("<" + tagName + ">")[0];
        // From [Stackoverflow: Copy all Attributes](http://stackoverflow.com/a/6753486/2096729)
        $.each(element.attributes, function() {
            newTag.setAttribute(this.name, this.value);
        });
        $(element).children().clone().appendTo(newTag);
        $(element).replaceWith(newTag);
        return newTag;
    }
})
$.fn.extend({
    replaceTag: function (tagName) {
        return this.map(function() {
            return jQuery.replaceTag(this, tagName);
        })
    }
})

var totalsSelector = '.renewals-total .list-item-totals';

var addressUtils = (function ($) {

    function hasAddressErrors() {
      return !validateAddresses();
    }

    function getBillingAddress() {
      var addressForm = $('div.billing-address form.address-form');

      return addressForm.serializeArray();
    }

    function getAddresses() {
      var deliveryAccounts = $('div.delivery-wrapper');
      var mappings = {};

      for (var i = 0; i < deliveryAccounts.length; i++) {
        var $delAccount = $(deliveryAccounts[i]);
        var deliveryAccountID = $delAccount.find('input[name="deliveryAccountID"]').val();
        var shippingAddress = $delAccount.find('form.address-form');
        var serialisedShippingAddress = shippingAddress.serializeArray();
        mappings[deliveryAccountID] = serialisedShippingAddress;
      }

      mappings.billingAddress = getBillingAddress();

      return mappings;
    }

    function editAddress(event) {
      var self = $(this);
      self.parents('.address-detail').find('.address-form__editable').toggleClass('hidden');
      self.parent().toggle();
    }

    function editAddressCancel(event) {
      event.preventDefault();
      var self = $(this);
      var addressDetail = self.parents('.address-detail');
      var addressForm = addressDetail.find('.address-form__editable');
      addressForm[0].reset();

      validateAddresses();

      addressForm.toggleClass('hidden');
      addressDetail.find('.single-line-address').toggle();
    }

    // update the UI to represent the change of address.
    function updateAddress(addressForm, isBilling) {
      var addressLines = [];
      addressForm.find('input,select').each(function() {
        var val = $(this).val();
        addressLines.push($(this).val().trim());
      });
      var parent = addressForm.parents('.address-detail');

      var isValid = validateAddresses();

      if (isValid) {
        parent.find('.address-form__editable').toggleClass('hidden');

        var singleLineAddress = parent.find('.single-line-address');

        singleLineAddress.find('div span').each(function(i, el) {
          el.textContent = addressLines[i];
        });
        singleLineAddress.toggle();
      }
    }

    // City, Country Name and Country Code are all required.
    function validateAddresses() {
      var deliveryAccounts = $('div.delivery-wrapper');
      var errors = {};

      for (var i = 0; i < deliveryAccounts.length; i++) {
        var $delAccount = $(deliveryAccounts[i]);
        var $addressForm = $delAccount.find('form.address-form');

        var shippingAddressErrors = validateAddressInputs($addressForm.find(':input'));

        if (shippingAddressErrors.length) {
          errors[$delAccount.prop('id')] = shippingAddressErrors;
        }
      }

      var billingAddress = $('.billing-address form.address-form');
      var billingAddressErrors = validateAddressInputs(billingAddress.find(':input'));

      if (billingAddressErrors.length) {
        errors['billing-address'] = billingAddressErrors;
      }

      const noErrors = Object.keys(errors).length === 0;

      showErrorMessages(noErrors, errors);

      return noErrors;
    }

    function showErrorMessages(noErrors, errors) {
      if (noErrors) {
        $('[data-address-editing-disabled-error]').addClass('hidden');
        $('.cart-buttons').show();
      } else {
        if ($('[data-address-editing-enabled]').data('address-editing-enabled') === false) {
          $('[data-address-editing-disabled-error]').removeClass('hidden');
          $('.cart-buttons').hide();
        }
      }
      showHeaderErrorMessages(noErrors, errors);
      showChildErrorMessages(errors);
    }

    function showHeaderErrorMessages(noErrors, errors) {
      var headerErrors = JSON.parse(JSON.stringify(errors));
      var errorMessageContainer = $('.address-error');
      var shippingErrorMessageContainer = errorMessageContainer.find('.msg__body--shipping');
      var billingErrorMessageContainer = errorMessageContainer.find('.msg__body--billing');
      if (noErrors) {
        shippingErrorMessageContainer.addClass('hidden');
        billingErrorMessageContainer.addClass('hidden');
        errorMessageContainer.addClass('hidden');
      } else {
        errorMessageContainer.removeClass('hidden');

        if (!!headerErrors['billing-address']) {
          billingErrorMessageContainer.removeClass('hidden');
          billingErrorMessageContainer.find('p').html('<div>' + formatHeaderErrorMessage({
            'billing-address': headerErrors['billing-address']
          }) + '</div>');
          delete headerErrors['billing-address'];
        } else {
          billingErrorMessageContainer.addClass('hidden');
        }

        if (Object.keys(headerErrors).length) {
          shippingErrorMessageContainer.removeClass('hidden');
          shippingErrorMessageContainer.find('p').html('<div>' + formatHeaderErrorMessage(headerErrors) + '</div>');
        } else {
          shippingErrorMessageContainer.addClass('hidden');
        }
      }
    }

    function showChildErrorMessages(errors) {
      var errorMessageContainers = $('.shipping-address-error, .billing-address-error');

      errorMessageContainers.each(function(index, container) {
        container = $(container);
        var parent = container.parents('.delivery-wrapper, .billing-wrapper');
        var hasErrors = !!errors[parent.prop('id')];

        if (hasErrors) {
          container.removeClass('hidden');
          container.find('p').html('<div>' + formatChildErrorMessage(parent.prop('id'), errors) + '</div>');
        } else {
          container.addClass('hidden');
        }
      });
    }

    function formatHeaderErrorMessage(errors) {
      let message = '<ul>';

      Object.keys(errors).forEach(function(key) {
        const len = errors[key].length

        if (len) {
          message += '<li><a href="#' + key + '">' + len + ' field' + (len === 1 ? ' has an error' : 's have errors') + '.</a></li>';
        }
      });

      return message + '</ul>'
    }

    function formatChildErrorMessage(key, errors) {
      const isEditable = !!$('#' + key + '[is-editable]').length;
      let message = '<ul>';

      errors[key].forEach(function(error) {
        if (isEditable) {
          message += '<li><a href="#' + error[0] + '" class="jump-to-field" data-parent-id="' + key + '">' + error[1] + ' is required</a></li>';
        } else {
          message += '<li>' + error[1] + ' is required</li>'
        }
      });

      message += '</ul>';

      return message;
    }

    function validateAddressInputs(inputs) {
      var errors = [];

      inputs.each(function (index, input) {
        var $input = $(input);
        if ($input.attr('required')) {
          var formGrp = $input.parent();
          if ($input.val() === '' || $input.val() === undefined) {
            errors.push([$input.prop('id'), $input.siblings('label').text()]);
            formGrp.addClass('is-invalid');
          } else {
            if (errors.indexOf($input.prop('id')) >= 0) {
              errors.splice(errors.indexOf($input.prop('id')), 1);
            }
            formGrp.removeClass('is-invalid');
          }
        }
      });

      return errors;
    }

    function handleCountryChange() {
      if (!$(this).is(':hidden')) {
        var countryCode = $(this).val();
        var regions = regionsList[countryCode];
        var $form = $(this).parents('form');
        var $regionInput = $form.find('[name="state"]');
        var previousValue = $regionInput.val();
        $regionInput.children().remove();
        if (regions) {
          $regionInput = $regionInput.replaceTag('select');
          $regionInput.append($('<option/>'));
          $regionInput.addClass('select');
          regions.forEach(function(region) {
            var $region = $('<option/>');
            $region.val(region.regionCode);
            $region.text(region.name);
            $regionInput.append($region);
            $regionInput.attr('required', 'required');
          });
          $regionInput.parent().addClass('form-grp--required');
        } else {
          $regionInput = $regionInput.replaceTag('input');
          $regionInput.removeAttr('required');
          $regionInput.removeClass('select');
          $regionInput.parent().removeClass('form-grp--required');
        }
        $regionInput.val(previousValue);
      }
    }
    $('[name="country"]').each(handleCountryChange); // Ensure initial state
    $('[name="country"]').on('change', handleCountryChange);
    $('a[href="#edit-address"]').on('click', function(event) {
      event.preventDefault();
      editAddress.call(this);
    });
    $('.address [type="reset"]').on('click', editAddressCancel);

    return {
      editAddress: editAddress,
      editAddressCancel: editAddressCancel,
      updateAddress: updateAddress,
      validateAddresses: validateAddresses,
      getAddresses: getAddresses,
      hasAddressErrors: hasAddressErrors,
      handleCountryChange: handleCountryChange
    }
})(jQuery);

$('main').on('click', '.jump-to-field', function () {
  var self = $(this);
  var editLink = $('#' + self.data('parent-id') + ' a[href="#edit-address"]');

  if (!editLink.parent().is(':hidden')) {
    addressUtils.editAddress.call(editLink);
  }
});

[].slice.call(document.querySelectorAll('.renewal-status-title svg')).forEach(function (el) {
  el.setAttribute('class', '');
});

$('.renewal-status-title').click(function () {
  var self = $(this);
  self.toggleClass('collapsed');
  self.siblings('.sub-level-items').toggleClass('hidden');
});

[].slice.call(document.querySelectorAll('.delivery-title svg')).forEach(function (el) {
  el.setAttribute('class', '');
});

$('.delivery-title').click(function () {
  var self = $(this);
  self.toggleClass('collapsed');
  self.parents('.delivery-wrapper').find('.renewals-wrapper').toggleClass('hidden');
});

function updateTotalsDisplay(totals) {
  var cartTotalField = $(totalsSelector + ' .cart-total + .item-cart-total');
  var taxField = $(totalsSelector + ' .tax + .item-tax');
  var totalField = $(totalsSelector + ' .total-row + .total');
  var discountField = $(totalsSelector + ' .item-discount');
  var subTotalField = $(totalsSelector + ' .sub-total + .item-sub-total');
  var currSymbol = totalField.text().substr(0, 1);

  cartTotalField.text(currSymbol + totals.cartTotal);
  taxField.text(currSymbol + totals.taxAmount);
  totalField.text(currSymbol + totals.total);
  discountField.text(currSymbol + totals.discount);
  subTotalField.text(currSymbol + totals.subTotal);

  if (totals.discount === '0.00') {
    discountField.removeClass('negative');
  } else {
    discountField.addClass('negative');
  }
}

function recalculateTotals() {
  var cartTotal = 0;
  var taxAmount = 0;
  var totalAmount = 0;
  var discount = 0;
  var subTotal = 0;

  $('input[type="checkbox"][name^="order-journal"]:checked').each(function (index, checkbox) {
    var selectedTerm = $(checkbox).parents('article').find('[type="radio"]:checked');
    var cart = selectedTerm.data('item-renewal-sub-total');
    var tax = selectedTerm.data('item-renewal-tax-amount') || 0;
    var tot = selectedTerm.data('item-renewal-total-amount');
    var dis = selectedTerm.data('item-renewal-discount') || 0;

    cartTotal += parseFloat(cart);
    taxAmount += parseFloat(tax);
    totalAmount += parseFloat(tot);
    discount += parseFloat(dis);
    subTotal += parseFloat(cart) - parseFloat(dis)
  });

  updateTotalsDisplay({
    cartTotal: cartTotal.toFixed(2),
    taxAmount: taxAmount.toFixed(2),
    total: totalAmount.toFixed(2),
    discount: discount.toFixed(2),
    subTotal: subTotal.toFixed(2)
  });
}

function initModal(modalSelector, triggerSelector) {
  var modal = $(modalSelector);

  $(triggerSelector).on('click', function(event) {
    event.preventDefault();
    modal.addClass('is-open');
  });

  $('.modal__close', modal).on('click', function(event) {
    event.preventDefault();
    modal.removeClass('is-open');
  });

  modal.on('click', function (event) {
    if (event.target.classList.contains('modal-wrapper')) {
      event.preventDefault();
      modal.removeClass('is-open');
    }
  });
}

function initTaxExemptModal() {
  initModal('.tax-exempt-modal', '#taxexempt');
}

function initUpdateAddressModal() {
  initModal('.update-address-modal', 'a[href="#update-address"]');
}

$(function () {
  initTaxExemptModal();
  initUpdateAddressModal();
  addressUtils.validateAddresses();

  $('form.address-form').submit(function (event) {
    event.preventDefault();
    var $form = $(this);

    if ($form.parent().hasClass('billing-address')) {
      addressUtils.updateAddress($form, true);
    } else {
      addressUtils.updateAddress($form, false);
    }
  });

  $(document).ready(function () {
    $('.renewal-terms').find('label:first-of-type input[name^="selected-term-"]').prop('checked', true);
  });

  $('input[name^="selected-term"]').on('click', function (e) {
    var id = $(this).val();
    var currentPrice = $('.line-item-totals[data-renewal-term-id="' + id + '"]');
    currentPrice
      .addClass('selected-term')
      .siblings('.line-item-totals')
      .removeClass('selected-term');
    recalculateTotals();
    return true;
  });

  $('#checkout-button').on('click', function (e) {
    e.preventDefault();

    if (addressUtils.hasAddressErrors()) {
      return false;
    }

    if($(this).hasClass('disabled')) {
      return false;
    }

    var checkboxes = $('input[name^="order-journal"]:checked');
    if (!checkboxes.length) {
      $('.no-selection-error').removeClass('hidden');
      return false;
    } else {
      $('.no-selection-error').addClass('hidden');
    }

    var terms = $('input[name="terms"]');
    if (terms.not(':checked').length) {
      terms.parents('.form-grp').addClass('is-invalid');
      return false;
    }
    var data = [];

    checkboxes.each(function (i, checkbox) {
      var renewalItem = $(checkbox).val();
      var issn = renewalItem.split('|')[1];
      var term = $('input[name="selected-term-' + issn + '"]');
      if (term.length > 1) {
        data.push($('input[name="selected-term-' + issn + '"]:checked').val());
      } else {
        data.push(renewalItem);
      }
    });

    var currency = '';
    var products = [];
    checkboxes.each(function() {
      var journal = $(this).parents('article.renewal-item');
      var price = journal.find('.item-sub-total:visible').text().substr(1);
      currency = journal.data('currency');
      products.push({
        'name': journal.data('name'),
        'magento_id': journal.data('item-renewal-id'),
        'id': journal.data('issn'),
        'dimension1': '', // sku
        'dimension2': '', // division
        'price': price,
        'brand': journal.data('publisher'),
        'variant': 'Journal',
        'quantity': journal.data('quantity') || 0,
        'dimension3': true, //renewal
        'dimension4': '', //customerType
        'dimension6': 'single'
      });
    });
    var updatedAddresses = JSON.stringify(addressUtils.getAddresses());
    $.ajax({
      url: '/pay',
      data: {
        'purchaseOrderReference': $('#purchaseOrderReference').val(),
        'renewals': data,
        'addresses': updatedAddresses
      },
      method: 'post',
      success: function (response) {
        window.location.href = response.redirect;
      },
      error: function (err) {
        var errors = [];
        if(err.responseJSON && err.responseJSON.errorDetail && err.responseJSON.errorDetail.fieldKeyErrorMap) {
          var errorMap = err.responseJSON.errorDetail.fieldKeyErrorMap;

          for(var key in errorMap) {
            if(!errorMap.hasOwnProperty(key)) {
              continue;
            }
            var msg = errorMap[key],
                code;
            if(msg) {
              code = key.split('.');
              while(code.length > 2) {
                code.shift();
              }
              errors.push(code.join('.') + ': ' + msg);
            }
          }
        }

        if (errors.length) {
          var e = {};
          var f = errors.forEach(function(error) {
            var m = error.match(/(.+)\.(.+): (.+)/);
            if (!(m[1] in e)) {
              e[m[1]] = {};
            }
            e[m[1]][m[2]] = m[3];
          }, e);

          var html = '<ul>';
          for (parent in e) {
            html += '<li><p>' + parent.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) { return str.toUpperCase(); }) + '</p><ul>';
            for (child in e[parent]) {
              html += '<li><p><span>' + child.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }) + ':</span> <span>' + e[parent][child] + '</span><p>'
            }
            html += '</ul></li>';
          }
          html += '</ul>';
          var msgDanger = $('[data-js-message]');
          msgDanger.find('.msg__body p').html(html);
          msgDanger.removeClass('hidden');
        }
      }
    });
  });

  function getRenewal(checkbox) {
      var $checkbox = $(checkbox);
      var value = $checkbox.val();
      value = value.split('|')[1];
      var prices = $checkbox.parents('.sub-level-item-selection').siblings('.line-item-totals');
      var price = $(prices).find('span.line-item-price-value').data('itemRenewalSubTotal');

      return { id: value, quantity: 1, price: price };
  }

  function toggleCheckboxes(checkboxes, checkit) {
    checkboxes.each(function (i, checkbox) {
      var renewal = getRenewal(checkbox);

      if (checkit === true) {
        window.appData.push({
          event: 'cartAdd',
          content: [renewal]
        });
      } else {
        window.appData.push({
          event: 'cartRemove',
          content: [renewal]
        });
      }
      $(checkbox).prop('checked', checkit);
    });
  }

  $('input[type="checkbox"][name^="delivery"]:not(.all-items)').click(function (e) {
    var target = $(e.target);
    var checkit = target.is(':checked');

    toggleCheckboxes(target.parents('.renewal-status').find('input[type="checkbox"][name^="order-journal"]'), checkit);

    updateParentCheckbox(target);

    recalculateTotals();
  });

  $('input[type="checkbox"][name^="delivery"].all-items').click(function (e) {
    var target = $(e.target);
    var checkit = target.is(':checked');

    toggleCheckboxes(
      target.parents('.delivery-wrapper')
        .find(
          'input[type="checkbox"][name^="order-journal"], input[type="checkbox"][name^="delivery"]:not(.all-items)'
        ),
      checkit
    );

    recalculateTotals();
  });

  $('input[type="checkbox"][name^="order-journal"]').click(function (e) {
    var renewal = getRenewal(this);
    var self = $(this);
    var parent = self.parents('.renewal-status');
    var orderJornalCheckboxes = parent.find('input[type="checkbox"][name^="order-journal"]');

    if (self.is(':checked')) {
      window.appData.push({
        event: 'cartAdd',
        content: [renewal]
      });

    } else {
      window.appData.push({
        event: 'cartRemove',
        content: [renewal]
      });
    }
    var checked = orderJornalCheckboxes.filter(':checked').length;
    var deliveryCheckbox = parent.find('.checkbox__delivery input[type="checkbox"]');
    if (deliveryCheckbox.length) {
      if (checked === orderJornalCheckboxes.length) {
        deliveryCheckbox.prop('checked', true);
        deliveryCheckbox.prop('indeterminate', false);
      } else if (checked) {
        deliveryCheckbox.prop('checked', false);
        deliveryCheckbox.prop('indeterminate', true);
      } else if (checked === 0) {
        deliveryCheckbox.prop('checked', false);
        deliveryCheckbox.prop('indeterminate', false);
      }
    }
    updateParentCheckbox(self);
    recalculateTotals();
  });

  function updateParentCheckbox(child) {
    var parent = child.parents('.delivery-wrapper');
    var orderJornalCheckboxes = parent.find('input[type="checkbox"][name^="order-journal"]');
    var checked = orderJornalCheckboxes.filter(':checked').length;
    var parentCheckbox = parent.find('.checkbox__delivery input[type="checkbox"].all-items');

    if (checked === orderJornalCheckboxes.length) {
      parentCheckbox.prop('checked', true);
      parentCheckbox.prop('indeterminate', false);
    } else if (checked) {
      parentCheckbox.prop('checked', false);
      parentCheckbox.prop('indeterminate', true);
    } else if (checked === 0) {
      parentCheckbox.prop('checked', false);
      parentCheckbox.prop('indeterminate', false);
    }
  }

  var pageHeader = document.querySelector('.page-header');
  var backToTop = document.querySelector('.back-to-top');

  if (backToTop) {
    window.onscroll = function() {
      var bounding = pageHeader.getBoundingClientRect();

      if (bounding.top + bounding.height >= 0) {
        backToTop.classList.add('hidden');
      } else {
        backToTop.classList.remove('hidden');
      }
    };

    backToTop.onclick = function(event) {
      event.preventDefault();
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }

  $('.delivery-title').each(function (i, e) {
    updateParentCheckbox($(e));
  });
  recalculateTotals();
});

Stickyfill.add(document.querySelectorAll('.renewals-list__totals, .back-to-top'));
