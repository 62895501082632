function AddressLookup() {
    if (window.pca && window.pca.Address) {
        // Adds the ability to easily convert a NodeList to an array
        if (window.NodeList && !NodeList.prototype.asArray) {
            NodeList.prototype.asArray = function (thisArg) {
                return [].slice.call(thisArg || this);
            };
        }

        var activeForm;
        document.querySelectorAll('form.address-form').asArray().forEach(function(node) {
            node.addEventListener('change', function() {
                activeForm = node;
            });
        });

        var fields = document.querySelectorAll('form.address-form input[name=address1]').asArray().map(function(node) {
            return { element: node, field: "Line1", mode: pca.fieldMode.SEARCH };
        });

        var pcaControl = new pca.Address(fields, pcaConfig);

        function updateLocation(addressForm, address) {
            // Country need to be done first so the state field can be correctly update to either a dropdown or free text
            addressForm.querySelector('[name=country]').value = address.CountryIso2;
            addressUtils.handleCountryChange.apply(addressForm.querySelector('[name=country]'));
            if (address.Company) {
                addressForm.querySelector('[name=address1]').value = address.Company;
                addressForm.querySelector('[name=address2]').value = address.Line1;
                addressForm.querySelector('[name=address3]').value = address.Line2;
            } else {
                addressForm.querySelector('[name=address1]').value = address.Line1;
                addressForm.querySelector('[name=address2]').value = address.Line2;
                addressForm.querySelector('[name=address3]').value = address.Line3;
            }
            addressForm.querySelector('[name=townCity]').value = address.City;
            addressForm.querySelector('[name=state]').value = address.Province || address.ProvinceName;
            addressForm.querySelector('[name=postcodeZip]').value = address.PostalCode;
        }

        pcaControl.listen('populate', function (address) {
            if (activeForm) {
                updateLocation(activeForm, address);
            }
        });

    }
}

module.exports = {
    AddressLookup
};
